import { GeocoderAddressComponent } from '@agm/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { NgbDate, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { IAddress } from '@otrack-lib/models/order/delivery-info.model';
import * as moment from 'moment';
import { DateRangeTypes } from './../../enums/date-range-enums';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  dateFilterTypes: { key: string; value: string }[] = [];

  constructor() {
    this.dateFilterTypes.push({ key: 'today', value: 'Today' });
    this.dateFilterTypes.push({ key: 'yesterday', value: 'Yesterday' });
    this.dateFilterTypes.push({ key: 'this-week', value: 'This Week' });
    this.dateFilterTypes.push({ key: 'this-month', value: 'This Month' });
    this.dateFilterTypes.push({ key: 'this-quarter', value: 'This Quarter' });
    this.dateFilterTypes.push({ key: 'this-year', value: 'This Year' });
    this.dateFilterTypes.push({ key: 'last-three-days', value: 'Last Three Days' });
    this.dateFilterTypes.push({ key: 'last-week', value: 'Last Week' });
    this.dateFilterTypes.push({ key: 'last-month', value: 'Last Month' });
    this.dateFilterTypes.push({ key: 'last-year', value: 'Last Year' });
    this.dateFilterTypes.push({ key: 'last-100-days', value: 'Last 100 Days' });
    this.dateFilterTypes.push({ key: 'last-365-days', value: 'Last 365 Days' });
  }


  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }


  getDateRange(key: string | DateRangeTypes): { from: Date; to: Date } {
    let fromDate: Date, toDate: Date;

    switch (key) {
      case 'today': {
        fromDate = new Date();
        toDate = new Date();
        break;
      }
      case 'yesterday': {
        fromDate = moment()
          .add(-1, 'days')
          .toDate();
        toDate = moment()
          .add(-1, 'days')
          .toDate();
        break;
      }
      case 'this-week': {
        fromDate = moment()
          .day(1)
          .toDate();
        toDate = moment()
          .day(7)
          .toDate();
        break;
      }
      case 'this-month': {
        fromDate = moment()
          .startOf('month')
          .toDate();
        toDate = moment()
          .endOf('month')
          .toDate();
        break;
      }
      case 'this-quarter': {
        fromDate = moment()
          .quarter(moment().quarter())
          .startOf('quarter')
          .toDate();
        toDate = moment()
          .quarter(moment().quarter())
          .endOf('quarter')
          .toDate();
        break;
      }
      case 'this-year': {
        fromDate = moment()
          .startOf('year')
          .toDate();
        toDate = moment()
          .endOf('year')
          .toDate();
        break;
      }
      case 'last-three-days': {
        fromDate = moment()
          .add(-3, 'days')
          .toDate();
        toDate = moment().toDate();
        break;
      }
      case 'last-week': {
        fromDate = moment()
          .day(1)
          .days(-2)
          .day(1)
          .toDate();
        toDate = moment()
          .day(1)
          .days(-2)
          .day(7)
          .toDate();
        break;
      }
      case 'last-month': {
        fromDate = moment()
          .subtract(1, 'month')
          .startOf('month')
          .toDate();
        toDate = moment()
          .subtract(1, 'month')
          .endOf('month')
          .toDate();
        break;
      }
      case 'last-year': {
        fromDate = moment()
          .subtract(1, 'year')
          .startOf('year')
          .toDate();
        toDate = moment()
          .subtract(1, 'year')
          .endOf('year')
          .toDate();
        break;
      }
      case 'last-30-days': {
        fromDate = moment()
          .add(-30, 'days')
          .toDate();
        toDate = moment().toDate();
        break;
      }
      case 'last-100-days': {
        fromDate = moment()
          .add(-100, 'days')
          .toDate();
        toDate = moment().toDate();
        break;
      }
      case 'last-365-days': {
        fromDate = moment()
          .add(-365, 'days')
          .toDate();
        toDate = moment().toDate();
        break;
      }
      case 'all': {
        fromDate = moment('2000-01-01').toDate();
        toDate = moment().toDate();
        break;
      }
      default: {
        fromDate = moment()
          .add(-365, 'days')
          .toDate();
        toDate = moment().toDate();
        break;
      }
    }

    return { from: fromDate, to: toDate };
  }

  getDateFromStringDate(dateStr: string) {
    return new Date(dateStr);
  }

  getNgbDateFromDate(date: Date): NgbDate {
    return new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate());
  }

  getNgbDateFromDateObj(date: Date): NgbDate {
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  getNgbDateFromStringDate(dateStr: string): NgbDate {
    return this.getNgbDateFromDate(this.getDateFromStringDate(dateStr));
  }

  getStringDate(inDate: Date): string {
    return moment(inDate).format('YYYY-MM-DD');
  }

  getStringDateWithDayAdd(addDays: number): string {
    return moment()
    .add(addDays, 'days')
    .format('YYYY-MM-DD');
  }

  getDateFromNbgDate(inNbdStruct: NgbDateStruct): Date {
    const month = inNbdStruct.month - 1; // moment month index start from 0
    return moment().year(inNbdStruct.year).month(month).date(inNbdStruct.day).toDate();
  }

  getStringDateFromNbgDate(date: NgbDateStruct): string {
    return this.getStringDate(this.getDateFromNbgDate(date));
  }

  getStringTime(inDate: Date): string {
    return moment(inDate).format('HH:mm');
  }

  getCustomDateString(inDate: Date, format: string): string {
    return moment(inDate).format(format);
  }

  /**
   * Build url parameters key and value pairs from array or object
   * @param obj
   */
  urlParam(obj: any): string {
    return Object.keys(obj)
      .map(k => k + '=' + encodeURIComponent(obj[k]))
      .join('&');
  }

  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   * @see https://stackoverflow.com/a/34749873/1316921
   */
  mergeDeep(target: { [x: string]: any; }, ...sources: any[]) {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  getPath(obj: { [x: string]: any; }, val: any, path?: string) {
    path = path || '';
    let fullpath = '';
    for (const b in obj) {
      if (obj[b] === val) {
        return path + '/' + b;
      } else if (typeof obj[b] === 'object') {
        fullpath = this.getPath(obj[b], val, path + '/' + b) || fullpath;
      }
    }
    return fullpath;
  }

  getFindHTTPParams(queryParams: {
    filter: string; sortOrder: string;
    sortField: string; pageNumber: { toString: () => string; }; pageSize: { toString: () => string; };
  }): HttpParams {
    const params = new HttpParams()
      .set('lastNamefilter', queryParams.filter)
      .set('sortOrder', queryParams.sortOrder)
      .set('sortField', queryParams.sortField)
      .set('pageNumber', queryParams.pageNumber.toString())
      .set('pageSize', queryParams.pageSize.toString());

    return params;
  }

  getHTTPHeader() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  fieldErrorCondition(
    fieldName: any,
    additionalCheck: boolean = false,
    formSubmit: boolean = false
  ): boolean {
    // Additional check is true
    if (additionalCheck) {
      return true;
    }

    if (fieldName) {
      return fieldName.invalid && (fieldName.dirty || fieldName.touched || formSubmit);
    }
    return false;
  }

  isFormControlValid(control: AbstractControl) {
    return control.valid && control.value;
  }

  // Convert PX to REM
  _rem(pxValue: string | number): string {

    const baselineRem = 16 / 1;
    if (typeof pxValue === 'string') {
      pxValue = pxValue.replace('px', '');
    }
    return (pxValue as number / baselineRem) + 'rem';
  }

  // Scroll back to top of given element
  scrollToTop(element: { scrollTop: number; }, scrollDuration: number) {
    if (!element || !element.scrollTop) { return; }

    const scrollStep = element.scrollTop / (scrollDuration / 15);
    let scrollInterval: any;

    // Clear interval either way in case something goes wrong
    setTimeout(() => { clearInterval(scrollInterval); }, (scrollDuration * 2));
    scrollInterval = setInterval(() => {
      if (element.scrollTop !== 0) { element.scrollTop -= scrollStep; } else { clearInterval(scrollInterval); }
    }, 15);
  }

  // Get Initials from name (Kirk Chisholm => KC)
  getInitials(name: string = ' ') {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names && names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  // Ref: https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
  hashCode(str: string) { // java String#hashCode
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
      // tslint:disable-next-line:no-bitwise
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  intToRGB(i: number) {
    // tslint:disable-next-line:no-bitwise
    const c = (i & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return '00000'.substring(0, 6 - c.length) + c;
  }

  getColor(str: string = '') {
    return this.intToRGB(this.hashCode(str));
  }

  // Checks if color is lighter or darker - true means lighter
  colorLightness(color: string) {
    const c = color.substring(1);      // strip #
    const rgb = parseInt(c, 16);   // convert rrggbb to decimal
    // tslint:disable-next-line:no-bitwise
    const r = (rgb >> 16) & 0xff;  // extract red
    // tslint:disable-next-line: no-bitwise
    const g = (rgb >> 8) & 0xff;  // extract green
    // tslint:disable-next-line: no-bitwise
    const b = (rgb >> 0) & 0xff;  // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    if (luma < 150) {
      return false;
    }

    return true;
  }

  get latestTimestamp() {
    return Math.floor(Date.now() / 1000);
  }

  // Finds position of an HTML object - Ref: https://stackoverflow.com/questions/5007530/how-do-i-scroll-to-an-element-using-javascript
  findPosition(obj: any) {
    let curleft = 0;
    let curtop = 0;
    do {
      curleft += obj.offsetLeft;
      curtop += obj.offsetTop;
      // tslint:disable-next-line:no-conditional-assignment
    } while (obj = obj.offsetParent);

    return {
      left: curleft,
      top: curtop
    };
  }

  // Scoll to element by reference to its container
  scrollToElement(element: { offsetTop: number; }, container: { style: { position: string; }; scrollTop: number; }, offSet: number = 0) {

    if (!element || !container) { return; }

    try {
      let makeRelative = false;
      let direction = 'up';

      // Make container relative (if not already) so that offsetTop of element can be calculated properly
      if (container.style.position !== 'relative') { makeRelative = true; }
      if (makeRelative) { container.style.position = 'relative'; }

      const interval = setInterval(() => {
        const yPos = element.offsetTop - offSet;
        let yScroll = container.scrollTop;

        if (yPos <= yScroll) {
          direction = 'up';
          yScroll -= 50;
        } else {
          direction = 'down';
          yScroll += 50;
        }
        container.scrollTop = yScroll;

        if ((direction === 'up' && yPos >= yScroll) || (direction === 'down' && yPos <= yScroll)) {
          container.scrollTop = yPos;
          clearInterval(interval);
          // If container was made relative, remove relative property
          if (makeRelative) { container.style.position = null; }
        }
      }, 10);

      // Clear interval after few milliseconds just in case the logic fails
      setTimeout(() => {
        clearInterval(interval);
        // If container was made relative, remove relative property
        if (makeRelative) { container.style.position = null; }
      }, 2000);

    } catch (e) { console.error('Exception in "scrollToElement" function:', e); }
  }

  extractNumbers(value: any): number {
    if (!value) { return 0; }

    let num = value;
    if (isNaN(value)) {
      // Extract digits, minus sign and decimal
      num = value.toString().replace(/[^\d.-]/g, '');
    }

    return Number(num);
  }

  // Helper for templates
  parseFloat(num: string) {
    return parseFloat(num);
  }

  // Converts local file to base64
  /* Usage
    this.getFileData( file, 'base64' )
    .then( imageData => {  //consol.log('IMAGE:', imageData); })
    .catch( error => {  //consol.log('Error: ', error); });
  */
  getFileData(file: any, type: string = 'base64') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      switch (type) {
        default:
        case 'base64': reader.readAsDataURL(file); break;
        case 'arrayBuffer': reader.readAsArrayBuffer(file); break;
        case 'binaryString': reader.readAsBinaryString(file); break; 	// Experimental
        case 'text': reader.readAsText(file); break;
      }
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  // Convert base64 To Blob (without losing data)
  // Copied from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
  b64toBlob(b64Data: string, contentType: string = '', sliceSize: number = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  // Add space after comma 'abc,abc' => 'abc, abc'
  commaSeparate(str: string | string[]): string {
    if (!str) { return; }

    if (typeof str === 'object') {
      // .slice() used for shallow copy or array
      return str.slice().join(', ');
    } else {
      return str.split(/\s*,\s*/).join(', ');
    }
  }

  // Converts a string to hash - Copied from https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  stringToHash(str: string): number {
    // tslint:disable-next-line: no-bitwise
    return str.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
  }

  getSupplierAdjustedStatus(status: string) {
    switch (status.toLowerCase().trim()) {
      case 'draft':
        return 'Draft';

      case 'submitted':
        return 'Processing';

      case 'emailfailed':
        return 'Email Failed';

      case 'openedbysupplier':
        return 'Opened By Supplier';

      case 'accepted':
        return 'Accepted';

      case 'shipped':
        return 'Shipped';

      case 'received':
        return 'Received';
    }

    return status;
  }

  getSupplierDotColor(status: string) {
    switch (status.toLowerCase().trim()) {
      case 'draft':
        return 'grey';

      case 'submitted':
        return 'yellow';

      case 'emailfailed':
        return 'orange';

      case 'openedbysupplier':
        return 'yellow';

      case 'accepted':
        return 'green';

      case 'shipped':
        return 'green';

      case 'received':
        return 'black';
    }

    return 'blank';
  }

  getOrderAdjustedStatus(status: string) {
    switch (status.toLowerCase().trim()) {
      case 'draft':
        return 'Draft';

      case 'partiallysubmitted':
        return 'Partially Submitted';

      case 'submitted':
        return 'Submitted';

      case 'processing':
        return 'Processing';

      case 'emailfailed':
        return 'Email Failed';

      case 'openedbysupplier':
        return 'Opened By Supplier';

      case 'partiallyaccepted':
        return 'Partially Accepted';

      case 'accepted':
        return 'Accepted';

      case 'partiallyshipped':
        return 'Partially Shipped';

      case 'shipped':
        return 'Shipped';

      case 'partiallyreceived':
        return 'Partially Received';

      case 'received':
        return 'Received';
    }

    return status;
  }

  getOrderDotColor(status: string) {
    switch (status.toLowerCase().trim()) {
      case 'draft':
        return 'grey';

      case 'partiallysubmitted':
      case 'submitted':
      case 'processing':
        return 'yellow';

      case 'emailfailed':
        return 'orange';

      case 'openedbysupplier':
        return 'yellow';

      case 'partiallyaccepted':
      case 'accepted':
        return 'green';

      case 'partiallyshipped':
      case 'shipped':
      case 'shipping':
        return 'green';

      case 'partiallyreceived':
      case 'received':
        return 'black';
    }

    return 'blank';
  }

  getProposalAdjustedStatus(status: string) {
    switch (status.toLowerCase().trim()) {
      case 'draft':
        return 'Draft';

      case 'submitted':
        return 'Submitted';

      case 'emailfailed':
        return 'Email Failed';

      case 'openedbyclient':
        return 'Opened By Client';

      case 'accepted':
        return 'Accepted';

      case 'declined':
        return 'Declined';

      case 'delayed':
        return 'Changes Required';

      case 'completed':
        return 'Completed';
    }

    return status;
  }

  getProposalDotColor(status: string) {
    switch (status.toLowerCase().trim()) {
      case 'draft':
        return 'grey';

      case 'submitted':
        return 'yellow';

      case 'emailfailed':
        return 'orange';

      case 'openedbyclient':
        return 'yellow';

      case 'accepted':
        return 'green';

      case 'declined':
        return 'red';

      case 'delayed':
        return 'yellow';

      case 'completed':
        return 'black';
    }

    return 'blank';
  }

  // Logic Copied from https://gist.github.com/ecarter/1423674
  // Order an array of objects based on another array of order for a specific key
  /*
    Example 1:
      itemArray = [ { id: 2, label: 'Two' }, { id: 3, label: 'Three' }, { id: 1, label: 'One'} ];
      itemOrder = [ 1, 2, 3 ];
      orderedArray = mapOrder(itemArray, itemOrder, 'id');

    Example 2:
      itemArray = [ 5, 10, 3, 15 ];
      itemOrder = [ 3, 15, 5, 10 ];
      orderedArray = mapOrder(itemArray, itemOrder);
  */
  mapOrder(array: any[], order: string | any[], key?: string | number) {
    array.sort((a: { [x: string]: any; }, b: { [x: string]: any; }) => {
      let A: any;
      let B: any;
      if (key) {
        A = a[key];
        B = b[key];
      } else {
        A = a;
        B = b;
      }
      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  }

  // Calculates Gross margin (profit)
  calculateProfit(sellPrice: any, cost: number) {
    if (sellPrice == null || cost == null) { return 0; }

    if (isNaN(sellPrice)) {
      sellPrice = parseFloat(sellPrice.replace(/[^\d\.]*/g, ''));	// Extract all digits
    }

    let profit = 0;
    if (sellPrice > 0) {
      profit = +(((sellPrice - cost) / sellPrice) * 100).toFixed(1);		// Limit decimal places to 1 - '+' to make it numeric
    }

    return profit;
  }

  // Check if all the properties of an object are empty
  checkIfObjectEmpty(object: { [s: string]: unknown; } | ArrayLike<unknown>): boolean {
    const isEmpty = Object.values(object).every(x => (x === null || x === '' || x === undefined));

    return isEmpty;
  }

  // Shallow Compare two objects
  isEquivalent(a: object, b: object) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    // for ( let i = 0; i < aProps.length; i++ ) {
    for (const propName of aProps) {
      // If values of same property are not equal,
      // objects are not equivalent
      if (a[propName] !== b[propName]) {
        return false;
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }

  returnDeepCopy(item: any) {
    return JSON.parse(JSON.stringify(item));	// Deep copy - otherwise arrays are still reference copied;
  }

  exceptionLog(e: { message: any; }) {
    if (e.message) {
      console.error(e.message);
    } else {
      console.error(e);
    }
  }

  isBarcodeValid(value: string): boolean {
    const barcode = this.toInteger(value);
    return !isNaN(barcode) && value.length > 1;
  }
}

export function ValidateUPCBarCode(controlName: string) {
  return (formGroup: FormGroup) => {
    const barCodeControl = formGroup.controls[controlName];

    if (barCodeControl.errors) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    if (barCodeControl.value) {
      if (
        !(
          barCodeControl.value.length === 6 ||
          barCodeControl.value.length === 8 ||
          barCodeControl.value.length === 11 ||
          barCodeControl.value.length === 12 ||
          barCodeControl.value.length === 13
        )
      ) {
        barCodeControl.setErrors({ ValidateUPCBarCode: true });
      } else {
        barCodeControl.setErrors(null);
      }
    }
    // set error on matchingControl if validation fails
  };
}

export function ValidateSalePrice(control: FormControl) {
  if (Number(control.value) <= 0) {
    return { nonZero: true };
  } else {
    return null;
  }
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function parseGoogleAddress(address_components?: GeocoderAddressComponent[]): IAddress {

  let streetNumberFilled = false;
  let address = null;
  let city = null;
  let zip = null;
  let stateCode = null;
  let countryCode = null;

  for (const addressComponent of address_components) {
    const addressType = addressComponent.types[0];
    const longValue = addressComponent.long_name;
    const shortValue = addressComponent.short_name;

    switch (addressType) {
      case 'street_number':
        address = longValue;
        streetNumberFilled = true;
        break;

      case 'route':
        // Join Street Number and Route in one field
        if (streetNumberFilled) {
          let _address = address;
          _address += ' ' + longValue;
          address = _address;
        } else {
          address = longValue;
        }
        break;

      case 'sublocality_level_1':
      case 'locality':
        city = longValue;
        break;

      case 'postal_code':
        zip = longValue;
        break;

      case 'administrative_area_level_1':
        stateCode = shortValue;
        break;

      case 'country':
        countryCode = shortValue;
        break;
    }
  }

  streetNumberFilled = false;

  const output: IAddress = {
    address1: address,
    city: city,
    postalCode: zip,
    state: stateCode,
    country: countryCode,
  };

  return output;
}

export function calculateProfitMargin(salePrice: number, cost: number): number {
  let margin = 0;
  if (salePrice && salePrice > 0 && cost) {
    margin = Number((((salePrice - cost) / salePrice) * 100).toFixed(2));
  }
  return margin;
}

export function calculateSalePrice(cost: number, margin: number, salePrice: number): number {
  let newSalePrice = 0;
  if (cost && margin) {
    newSalePrice = Number((cost / (1 - (margin / 100))).toFixed(2));
  }

  if (newSalePrice > 0) {
    return newSalePrice;
  }

  if (salePrice && salePrice > 0) {
    return salePrice;
  }

  return newSalePrice;
}
