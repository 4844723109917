import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ErrorModel } from '@otrack-lib/models/error.model';
import { PagedData } from '@otrack-lib/models/paged-data.model';
import { IPricingTier } from '@otrack-lib/models/product/pricing.tier.model';
import { IProductCategory, IProductCategoryView } from '@otrack-lib/models/product/product.category';
import { IProduct, IProductList, IProductOption } from '@otrack-lib/models/product/product.model';
import { IPricingData, IProductPricing } from '@otrack-lib/models/product/product.pricing.model';
import { IQuickProductDetail } from '@otrack-lib/models/product/quick-order.models';
import { QueryParamsModel } from '@otrack-lib/models/query-params.model';
import { QueryResultsModel } from '@otrack-lib/models/query-results.model';
import { FileItem, FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpUtilsService } from '../_base/crud';
import { BaseApiService } from './baseapi.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseApiService {
  pricingTier$ = new BehaviorSubject<IPricingTier[]>([]);

  readonly API_URLS = {
    products: `${this.baseApiUrl}/v1/products`,
    productList: `${this.baseApiUrl}/v1/Products`,
    recalculateAvgCost: `${this.baseApiUrl}/v1/Products/RecalculateAvgCost`,
    odataProductList: `${this.baseApiUrl}/v1/odata/Products/List`,
    odataProductPricingList: `${this.baseApiUrl}/v1/odata/Products/pricetiers`,
    productCategoryList: `${this.baseApiUrl}/v1/categories`,
    publicProductCategoryList: `${this.baseApiUrl}/v1/public/products/ProductCategories`,
    pricingTier: `${this.baseApiUrl}/v1/pricetiers`,
    updateProductPricing: `${this.baseApiUrl}/v1/Products/pricetiers`,
    productsCategories: `${this.baseApiUrl}/v1/Products/ProductCategories`,
    productNameSearch: `${this.baseApiUrl}/v1/odata/Products/Names`,
    productDetailbyName: `${this.baseApiUrl}/v1/odata/Products/ForCustomer`, // not used anymore
    customerProductDetail: `${this.baseApiUrl}/v1/customers/product`, // {customerid}/{productId}
    customers: `${this.baseApiUrl}/v1/customers`,
    adjustment: `${this.baseApiUrl}/v1/products/adjustment`,
    adjustments: `${this.baseApiUrl}/v1/products/adjustments`,
    productOptions: `${this.baseApiUrl}/v1/ProductOptions`,
    posSortOrder: `${this.baseApiUrl}/v1/products/pos/sortorder`,
  };

  private uploadError$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private uploader: FileUploader = null;
  maxFileSize = 5 * 1024 * 1024;
  allowedMimeType = [
    'image/png',
    'image/jpeg',
    'image/jpg'
  ];

  static getProductModel(res: any): IProduct {
    return {
      productId: res.productId,
      name: res.name ? res.name : res.productName,
      description: res.description,
      publicName: res.publicName,
      parentCategoryId: res.rootCategoryId ? res.rootCategoryId : res.parentCategoryId,
      parentCategoryName: res.rootCategoryName,
      categoryId: res.categoryId,
      basePrice: res.price || res.basePrice, // Kludge doing for quick hotfix..Edit Product Price SalePRice issue
      price: res.price,
      promotionPrice: res.promotionPrice,
      averageCost: res.averageCost,
      isTaxable: res.isTaxable,
      purchaseCost: res.purchaseCost,
      quantityInHand: res.quantityInHand,
      isActive: res.isActive,
      barcode: res.barcode,
      suggestedRetailPrice: res.suggestedRetailPrice,
      unitPerCase: res.unitPerCase,
      notes: res.notes,
      pricingTiers: res.pricingTiers,
      productUnit: res.productUnit,
      imageUrls: res.imageUrl ? [res.imageUrl] : null,  // TODO: api should return array
      isAgeRestricted: res.isAgeRestricted ? res.isAgeRestricted : false,
      minAge: res.minAge ? res.minAge : 0,
      tags: res.tags,
      showOnPos: res.showOnPos,
      showOnWeb: res.showOnWeb,
      initialStockCost: res.initialCost,
      initialStockQty: res.initialStock,
      options: ProductService.mapProductOptionsFromProduct(res),
      showCostModifiedAlert: res.showCostModifiedAlert,
      isFeatured: res.isFeatured,
      isNewArrival: res.isNewArrival
    };
  }

  static mapProductOptionsFromProduct(res: any): IProductOption[] {
    const _optionsType = [];
    if (res.options) {
      res.options.forEach(item => {
        _optionsType.push({
          type: item.type,
          label: item.label
        });
      });
    }

    return _optionsType;
  }

  static mapProductOptionsModel(res: any): IProductOption[] {
    const results = [];
    if (res.data) {
      res.data.forEach(item => {
        const _options: IProductOption[] = [];
        if (item.options) {
          item.options.forEach(opt => {
            _options.push({
              id: opt.id,
              key: opt.key,
              value: opt.value
            });
          });
        }

        results.push({
          id: item.id,
          name: item.name,
          type: item.type ? item.type : item.name, // type and name is same (check with Zeeshan)
          label: item.label,
          options: _options
        });
      });
    }
    return results;
  }

  static mapProductOptionFromProductOrOrder(options: any): IProductOption[] {
    const results = [];
    if (options && options.length > 0) {
      options.forEach(opt => {
        results.push({
          type: opt.type,
          key: opt.key,
          value: opt.value,
        });
      });
    }
    return results;
  }

  static getProductModelForProductCategories(res: any): IProduct {
    return {
      productId: res.productId,
      name: res.productName,
      description: res.description,
      parentCategoryId: res.parentCategoryId,
      categoryId: res.categoryId,
      basePrice: res.price,
      price: res.price,
      promotionPrice: res.promotionPrice,
      isTaxable: res.isTaxable,
      purchaseCost: res.customCost !== 0 ? res.customCost : res.cost,
      quantityInHand: res.quantityInHand,
      isActive: res.isActive,
      barcode: res.barcode,
      suggestedRetailPrice: res.suggestedRetailPrice,
      unitPerCase: res.unitPerCase,
      notes: res.notes,
      productUnit: res.productUnit,
      imageUrls: res.imageUrl ? [res.imageUrl] : null,  // TODO: api should return array
      isAgeRestricted: res.isAgeRestricted ? res.isAgeRestricted : false,
      minAge: res.minAge ? res.minAge : 0,
      tags: res.tags,
    };
  }

  static getProductListModel(res: any): IProductList {
    return {
      productId: res.productId,
      productName: res.productName,
      productDescription: res.description,
      publicName: res.publicName,
      rootCategoryName: res.rootCategoryName,
      categoryName: res.categoryName,
      price: res.price,
      promotionPrice: res.promotionPrice,
      isTaxable: res.isTaxable,
      purchaseCost: res.purchaseCost,
      averageCost: res.averageCost,
      quantityInHand: res.quantityInHand,
      isActive: res.isActive,
      suggestedRetailPrice: res.suggestedRetailPrice,
      barcode: res.barcode,
      unitPerCase: res.unitPerCase ? res.unitPerCase : 1,
      notes: res.notes,
      productUnit: res.productUnit,
      imageUrls: res.imageUrl ? [res.imageUrl] : null,  // TODO: api should return array
      isAgeRestricted: res.isAgeRestricted ? res.isAgeRestricted : false,
      minAge: res.minAge ? res.minAge : 0,
      tags: res.tags ? res.tags : [],
      showOnPos: res.showOnPos,
      showOnWeb: res.showOnWeb,
      options: ProductService.mapProductOptionsFromProduct(res),
      showCostModifiedAlert: res.showCostModifiedAlert
    };
  }

  static getProductPricingListModel(res: any): IProductPricing {
    return {
      productId: res.productId,
      productName: res.productName,
      productDescription: res.productDescription,
      rootCategoryName: res.rootCategoryName,
      rootCategoryId: res.rootCategoryId,
      basePrice: res.salePrice,
      purchaseCost: res.cost,
      pricing: res.tierPrices ? ProductService.getProductPricingDetail(res.tierPrices) : []
    };
  }

  static getQuickProductDetailModel(res: any[]): IQuickProductDetail[] {
    return res.map(p => <IQuickProductDetail>{
      productId: p.productId,
      name: p.productName,
      description: p.description,
      basePrice: p.price,
      price: p.price,
      isTaxable: p.isTaxable,
      purchaseCost: p.customCost > 0 ? p.customCost : p.cost,
      quantityInHand: p.quantityInHand,
      rootCategoryName: p.rootCategoryName,
      categoryId: p.categoryId,
      categoryName: p.categoryName
    });
  }

  static getProductPricingDetail(res: any[]): IPricingData[] {
    const output: IPricingData[] = [];
    if (!res) {
      return output;
    }
    res.forEach(c => {
      output.push({
        id: c.tierId,
        price: c.price,
        isWebTier: c.isWebTier
      });
    });
    return output;
  }

  static getPricingTier(res: any[]): IPricingTier[] {
    const output: IPricingTier[] = [];
    if (!res) {
      return output;
    }
    res.forEach(c => {
      output.push({
        id: c.tierId,
        name: c.tierName,
        isWebTier: c.isWebTier
      });
    });
    return output;
  }


  static getPricingForApi(res: IPricingData[]): any[] {
    const output: any[] = [];
    if (!res) {
      return output;
    }
    res.forEach(c => {
      output.push({ tierId: c.id, price: c.price });
    });
    return output;
  }

  static getProductCategoryModel(res: any): IProductCategory {
    return {
      categoryId: res.categoryId,
      name: res.name,
      description: res.description,
      parentCategoryId: res.parentCategoryId,
      parentCategoryName: res.parentCategoryName,
      rootCategoryId: res.rootCategoryId,
      rootCategoryName: res.rootCategoryName,
      canDelete: res.canDelete,
      showOnWeb: res.showOnWeb,
    };
  }

  constructor(private _injector: Injector, private httpUtils: HttpUtilsService, private userService: UserService) {
    super(_injector);
  }

  getAllProducts(): Observable<IProduct[]> {
    return this.getProductList('', '', '', 0, 9999, false)
      .pipe(
        map(pagedResult => pagedResult.data
          .map(prdList => ProductService.getProductModel(prdList))
        )
      );
  }

  getProductList(
    searchName: string,
    sortField: string,
    sortDirection: string,
    pageIndex: number,
    pageSize: number,
    activeFilter?: boolean
  ): Observable<PagedData<IProductList>> {
    return this.getOdataProductList(searchName, sortField, sortDirection, pageIndex, pageSize, activeFilter)
      .pipe(
        // tap(resp => (this.countProductList = resp['@odata.count'] as number)),
        map(res => {
          const pagedData = new PagedData<IProductList>();
          pagedData.data = res.value as IProductList[];
          pagedData.totalElements = res['@odata.count'] as number;
          return pagedData;
        })
      );
  }

  getOdataProductList(
    searchName: string,
    sortField: string,
    sortDirection: string = 'asc',
    pageIndex: number = 0,
    pageSize: number,
    activeFilter?: boolean
  ): Observable<any> {
    // return this._http.get<any>('./assets/data/product-list.json');
    const url = `${this.API_URLS.odataProductList}`;
    let httpParams = new HttpParams()
      .set('$count', 'true')
      .set('$skip', (pageIndex * pageSize).toString())
      .set('$top', pageSize.toString());
    if (sortField) {
      httpParams = httpParams.set('$orderby', `${sortField} ${sortDirection}`);
    }
    let filterQuery = '';
    if (activeFilter) {
      filterQuery += `isActive eq ${activeFilter}`;
    }
    if (searchName) {
      if (filterQuery !== '') {
        filterQuery += ' and ';
      }
      filterQuery += `contains(tolower(productname),tolower('${searchName}'))`;
    }
    if (filterQuery !== '') {
      httpParams = httpParams.set('$filter', filterQuery);
    }

    return this.httpAuthGet(url, {
      params: httpParams
    })
      .pipe(catchError(this.handleError));
  }


  getPOSProducts(): Observable<IProduct[]> {
    const url = `${this.API_URLS.odataProductList}`;
    let httpParams = new HttpParams()
      .set('$count', 'true')
      .set('$top', '100'); //enable after INTEGRAION ################
    // .set('$top', '8');
    // httpParams = httpParams.set('$orderby', `POSDisplaySortOrder asc`); //ENABLE AFTER INTERARTION ################
    let filterQuery = '';
    // filterQuery += `isActive eq true`;
    filterQuery += `isActive eq true and showOnPos eq true`;
    httpParams = httpParams.set('$filter', filterQuery);

    return this.httpAuthGet(url, { params: httpParams })
      .pipe(
        map(res => {
          return res.value && res.value.length > 0 ? res.value.map(prod => ProductService.getProductModelForProductCategories(prod)) : [];
        }),
        catchError(this.handleError));
  }


  updatePosProductsSortOrder(productIds: number[]) {
    const dataToSend = {
      sortList: productIds
    };
    return this.httpAuthPost(`${this.API_URLS.posSortOrder}`, dataToSend);
  }


  getProductListByFilter(params: QueryParamsModel): Observable<QueryResultsModel> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = params.pageNumber * params.pageSize;
    dataParams['top'] = params.pageSize;
    if (params.getODataSearchFilterString().length > 0) {
      dataParams['vfilter'] = params.getODataSearchFilterString();
    }

    if (params.getODataOrderByFilterString()) {
      dataParams['orderby'] = params.getODataOrderByFilterString();
    }

    return this.httpAuthGet(
      `${this.API_URLS.odataProductList}?${this.generateODataQueryString(dataParams)}`
    ).pipe(
      map(res => {
        const output: IProductList[] = [];
        const total = +res['@odata.count'];

        for (const item of res['value']) {
          output.push(ProductService.getProductListModel(item));
        }
        return new QueryResultsModel(output, total);
      })
    );
  }

  exportProductListByFilter(params: QueryParamsModel , exportData: string): Observable<Blob> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = params.pageNumber * params.pageSize;
    dataParams['top'] = params.pageSize;
    if (params.getODataSearchFilterString().length > 0) {
      dataParams['vfilter'] = params.getODataSearchFilterString();
    }

    if (params.getODataOrderByFilterString()) {
      dataParams['orderby'] = params.getODataOrderByFilterString();
    }

    return this.httpAuthGet(
      `${this.API_URLS.odataProductList}?${this.generateODataQueryString(dataParams)}${exportData ? '&export=' + exportData : ''}`, { responseType: 'blob' }); // Expect a Blob response
  }

  getProduct(productId: number): Observable<IProduct> {
    return this.httpAuthGet(`${this.API_URLS.productList}/${productId}`).pipe(
      map(res => {
        return ProductService.getProductModel(res);
      })
    );
  }


  getProductCategoryView(parentProductId: number): Observable<any> {
    const productsUrl = this.API_URLS.productsCategories;
    return this.httpAuthGet(`${productsUrl}?categoryId=${parentProductId}`).pipe(
      map(res => {
        return {
          products: res.products.map((item: any) => ProductService.getProductModelForProductCategories(item)),
          categories: res.categories.map((item: any) => ProductService.getProductCategoryModel(item))
        } as IProductCategoryView;
      }),
      catchError(this.handleError));
  }

  getProductCategories(parentCategoryId: number = -1): Observable<IProductCategory[]> {
    return this.httpAuthGet(`${this.API_URLS.productCategoryList}/${parentCategoryId}`).pipe(
      map(res => {
        let output: IProductCategory[] = [];

        for (const item of res) {
          output.push(ProductService.getProductCategoryModel(item));
        }
        output = output.sort((a, b) => (a.name < b.name ? -1 : 1));
        return output;
      })
    );
  }

  updateProduct(product: IProduct): Observable<IProduct> {
    // console..log('updateProduct: ' + product);
    const dateToSend = { ...product, initialStock: product.initialStockQty, initialCost: product.initialStockCost };
    return this.httpAuthPut(`${this.API_URLS.productList}/${product.productId}`, dateToSend).pipe(
      map(res => {
        if (res) {
          const _product: IProduct = ProductService.getProductModel(res);
          return _product;
        }
        throw new ErrorModel(null, 'Product not found');
      })
    );
  }

  recalculateInventory(): Observable<any> {
    return this.httpAuthPost(`${this.API_URLS.recalculateAvgCost}`, '');
  }

  addProduct(product: IProduct): Observable<IProduct> {
    const dateToSend = { ...product, initialStock: product.initialStockQty, initialCost: product.initialStockCost };
    // console..log('addProduct: ' + product);
    return this.httpAuthPost(`${this.API_URLS.productList}`, dateToSend).pipe(
      map(res => {
        if (res) {
          const _product: IProduct = ProductService.getProductModel(res);
          return _product;
        }
      })
    );
  }

  updateProductActivation(productId: number, isActive: boolean): Observable<boolean> {
    const dataToSend = {
      isActive: isActive
    };
    const url = `${this.API_URLS.products}/${productId}/toggle`;
    return this.httpAuthPost(url, dataToSend).pipe(
      map(res => true)
    );
  }

  updateProductDisplayOnWebFlag(productId: number, displayOnWeb: boolean): Observable<boolean> {
    const dataToSend = {
      showOnWeb: displayOnWeb
    };
    const url = `${this.API_URLS.products}/${productId}/toggle`;
    return this.httpAuthPost(url, dataToSend).pipe(
      map(res => true)
    );
  }

  updateProductDisplayOnPOSFlag(productId: number, displayOnPOS: boolean): Observable<boolean> {
    const dataToSend = {
      showOnPos: displayOnPOS
    };
    const url = `${this.API_URLS.products}/${productId}/toggle`;
    return this.httpAuthPost(url, dataToSend).pipe(
      map(res => true)
    );
  }


  updateProductIsFeatured(productId: number, isFeatured: boolean): Observable<boolean> {
    const dataToSend = {
      isFeatured: isFeatured
    };
    const url = `${this.API_URLS.products}/${productId}/toggle`;
    return this.httpAuthPost(url, dataToSend).pipe(
      map(res => true)
    );
  }

  addPricingTier(name: string, isWebTier?: boolean): Observable<any> {
    const dataToSend = { tierName: name, isWebTier: isWebTier };
    return this.httpAuthPost(`${this.API_URLS.pricingTier}`, dataToSend).pipe(
      map(res => {
        const existinglst = this.pricingTier$.value;
        existinglst.push({
          id: res.tierId,
          name: res.tierName,
          isWebTier: res.isWebTier
        });
        this.pricingTier$.next(existinglst);
        return { id: res['tierId'], name: res['tierName'] };
      }),
      catchError((error: ErrorModel) => this.handleError(error))
    );
  }

  editPricingTier(priceTier: IPricingTier): Observable<any> {
    const dataToSend = {
      tierId: priceTier.id,
      tierName: priceTier.name,
      isWebTier: priceTier.isWebTier
    };
    return this.httpAuthPut(`${this.API_URLS.pricingTier}/${priceTier.id}`, dataToSend).pipe(
      map(res => {
        const existinglst = this.pricingTier$.value;
        const existing  = existinglst.find(c => c.id === priceTier.id);
        existing.name = priceTier.name;
        existing.isWebTier = priceTier.isWebTier;

        this.pricingTier$.next(existinglst);
        return {
          id: priceTier.id,
          name: priceTier.name,
          isWebTier: priceTier.isWebTier
        };
      }),
      catchError((error: ErrorModel) => this.handleError(error))
    );
  }

  loadPricingTiers(): Observable<boolean> {
    return this.httpAuthGet(`${this.API_URLS.pricingTier}`).pipe(
      map(res => {
        this.pricingTier$.next(ProductService.getPricingTier(res));
        return true;
      })
    );
  }

  getProductPricingListByFilter(params: QueryParamsModel): Observable<QueryResultsModel> {
    const dataParams = [];
    dataParams['count'] = true;
    dataParams['skip'] = params.pageNumber * params.pageSize;
    dataParams['top'] = params.pageSize;
    if (params.getODataSearchFilterString().length > 0) {
      dataParams['vfilter'] = params.getODataSearchFilterString();
    }

    if (params.getODataOrderByFilterString()) {
      dataParams['orderby'] = params.getODataOrderByFilterString();
    }

    return this.httpAuthGet(
      `${this.API_URLS.odataProductPricingList}?${this.generateODataQueryString(dataParams)}`
    ).pipe(
      map(res => {
        const output: IProductPricing[] = [];
        const total = +res['@odata.count'];

        for (const item of res['value']) {
          output.push(ProductService.getProductPricingListModel(item));
        }
        return new QueryResultsModel(output, total);
      })
    );
  }

  getProductPricingByProductId(productId: number): Observable<IProductPricing> {
    const dataParams = [];
    dataParams['vfilter'] = `productId eq ${productId}`;
    return this.httpAuthGet(
      `${this.API_URLS.odataProductPricingList}?${this.generateODataQueryString(dataParams)}`
    ).pipe(
      map(res => {
        // there will be always one IProductPricing per IProduct}
        return ProductService.getProductPricingListModel(res.value[0]);
      })
    );
  }

  updateProductPricing(productPricingUpdateList: IProductPricing[]): Observable<any> {
    const dataToSend: any[] = [];

    productPricingUpdateList.forEach(p =>
      dataToSend.push({
        productId: p.productId,
        salePrice: p.basePrice,
        tierPricing: ProductService.getPricingForApi(p.pricing)
      })
    );

    return this.httpAuthPost(`${this.API_URLS.updateProductPricing}`, dataToSend).pipe(
      map(res => {
        return true;
      }),
      catchError((error: ErrorModel) => this.handleError(error))
    );
  }

  getproductListBySearch(term: string, orderBy: string = 'ProductName'): Observable<any> {
    // return this._http.get<string[]>('./assets/dummy/productlistSearch.json');
    const productsNameSearchUrl = this.API_URLS.productNameSearch;
    const url = `${productsNameSearchUrl}?$filter=isActive eq true and (contains(tolower(ProductName),
                '${term.toLowerCase()}') or contains(tolower(Description),'${term.toLowerCase()}') or
                  contains(tolower(barcode),'${term.toLowerCase()}'))&$orderby=${orderBy}`;
    return this.httpAuthGet(url).pipe(
      map(res => res.value),
      catchError(this.handleError)
    );
  }

  getproductByName(customerId: number, productName: string): Observable<any> {
    const productsNameSearchUrl = this.API_URLS.productDetailbyName;
    const productNameEncoded = encodeURIComponent(productName);
    return this.httpAuthGet(`${productsNameSearchUrl}(CustomerId = ${customerId})?$filter=ProductName eq '${productNameEncoded}'`).pipe(
      map(res => ProductService.getQuickProductDetailModel(res.value)),
      catchError(this.handleError)
    );

  }

  getproductDetailByCustomer(customerId: number, productId: number): Observable<IQuickProductDetail> {
    const customerProductDetailUrl = this.API_URLS.customerProductDetail;
    // tslint:disable-next-line:max-line-length
    return this.httpAuthGet(`${customerProductDetailUrl}/${customerId}/${productId}`).pipe(catchError(this.handleError));
  }

  getProductDetailByBarcode(customerId: number, barcode: string): Observable<IQuickProductDetail[]> {
    const customersUrl = this.API_URLS.customers;
    return this.httpAuthGet(`${customersUrl}/${customerId}/products?barcode=${barcode}`).pipe(catchError(this.handleError));
  }

  getProductOptions(types: string[]): Observable<IProductOption[]> {
    /* const data = {
      'data': [
        {
          'id': 1,
          'name': 'ChickenCuts',
          'label': 'Chicken Cuts',
          'options': [
            {
              'id': 0,
              'key': '8pieces',
              'value': '8 pieces'
            },
            {
              'id': 0,
              'key': 'small',
              'value': 'Small Pieces'
            },
            {
              'id': 0,
              'key': 'full',
              'value': 'Full Chicken'
            }
          ]
        }
      ]
    };

    return new BehaviorSubject(ProductService.mapProductOptionsModel(data)).asObservable(); */


    let httpParams = new HttpParams();
    types.forEach(type => {
      httpParams = httpParams.append('keys', type);
    });

    const requestOptions = {
      params: httpParams
    };
    return this.httpAuthGet(`${this.API_URLS.productOptions}`, requestOptions).pipe(
      map(res => ProductService.mapProductOptionsModel(res))
    );
  }

  dismissCostWarning(productId: number, showCostModifiedAlert: boolean): Observable<boolean> {
    const dataToSend = {
      showCostModifiedAlert
    };
    const url = `${this.API_URLS.products}/${productId}/toggle`;
    return this.httpAuthPost(url, dataToSend).pipe(
      map(res => true)
    );
  }

  async uploadProductImage(productId: number, token: string) {
    const uo: FileUploaderOptions = {
      url: `${this.API_URLS.products}/${productId}/image`,
      isHTML5: true,
      itemAlias: 'document',
      allowedMimeType: this.allowedMimeType,
      autoUpload: false,
      maxFileSize: this.maxFileSize,
      method: 'PUT'
    };

    // const token = <string>await this.authService.getIdToken();
    uo.headers = [{
      name: 'Authorization',
      value: `Bearer ${token}`
    }];
    this.uploader.setOptions(uo);
    this.uploader.onBuildItemForm = () => { };
    this.uploader.uploadAll();

    return;
  }

  downloadProductImage(productId: number): Observable<any> {
    return this.httpAuthGet(`${this.API_URLS.products}/${productId}/image`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  deleteProductImage(productId: number) {
    return this.httpAuthDelete(`${this.API_URLS.products}/${productId}/image`);
  }

  getFileUploader(): FileUploader {
    this.uploader = null;
    this.uploader = new FileUploader({
      isHTML5: true,
      itemAlias: 'document',
      allowedMimeType: this.allowedMimeType,
      autoUpload: false,
      maxFileSize: this.maxFileSize,
      method: 'PUT'
    });
    this.uploader.onAfterAddingFile = item => this.onAfterAddingFile();
    this.uploader.onWhenAddingFileFailed = (item, filter, options) =>
      this.onWhenAddingFileFailed(item, filter);
    return this.uploader;
  }

  removeUploadedItemsFromQueue() {
    this.uploader.queue.forEach((item: FileItem) => {
      if (item.isUploaded) {
        item.remove();
      }
    });
  }

  private onAfterAddingFile() {
    this.uploadError$.next('');
  }

  private onWhenAddingFileFailed(item: FileLikeObject, filter: any) {
    switch (filter.name) {
      case 'fileSize':
        this.uploadError$.next(`Maximum upload size exceeded (${item.size} of ${this.maxFileSize} allowed)`);
        break;
      case 'mimeType':
        const allowedTypes = this.allowedMimeType.join();
        this.uploadError$.next(`Type "${item.type} is not allowed. Allowed types: "${allowedTypes}"`);
        break;
      default:
        this.uploadError$.next(`Unknown error (filter is ${filter.name})`);
    }
  }
}
