import { CustomerFilter } from './../../apps/client/src/app/modules/customer-list/models/customer-filter.model';

import { ExportFormat } from './../enums/exportformat.enum';
import { QBFilterTypes } from './../enums/filter.enums';

import * as moment from 'moment';
import { OrderTypes, ActiveFilterTypes, TaxableFilterTypes } from '@otrack-lib/enums';
import { SalesOrderStatus } from '@otrack-lib/enums/sales-order.status.enum';
import { SortDirection } from '@angular/material/sort';

export interface IFilters {
  isActive?: boolean;
  isQBCustomer?: boolean;
  isTaxable?: boolean;
  rootCategoryId?: string[];
}

 export interface IReportFilter {
  searchText?: string;
  isDescending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortColumn?: string;
  sortDirection?: 'asc' | 'desc' | SortDirection;
  customerFilterList?: any[];
  productFilterList?: any;
  userIds?: any;
  vendorFilterList?: any;
  orderType?: OrderTypes;
  saleOrderStatus?: SalesOrderStatus;
  activeType?: ActiveFilterTypes;
  qbCustomerType?: QBFilterTypes;
  taxableType?: TaxableFilterTypes;
  paymentTypeFilterList?: any;
  posActionTypeList?: any;
  ShiftIdList?: any;
  fromDate?: string;
  toDate?: string;
  asOfDate?: string;
  exportFormat?: ExportFormat;
  parentCategoryFilterList?: any;
  openBalance?: boolean;
  balance?: boolean;
  selectedCustomerName?: string;
  orderBys?: string[];
  cogsByCost?: boolean;
  columnFilter?: IColumnFilter[];
 }


export interface IColumnFilter {
  columnName: string;
  operator: string;
  value: string;
}

export class ReportFiltersModel implements IReportFilter {

  // constructor overrides
  constructor(_pageNumber: number = 1, _pageSize: number = 10000, _searchText: string = '') {
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
    this.searchText = _searchText;
    this.activeType = ActiveFilterTypes.Active;
    this.columnFilters = [];
  }
  searchText?: string;
  isDescending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  customerFilterList?: any[];
  productFilterList?: any;
  userIds?: any;
  vendorFilterList?: any;
  orderType?: OrderTypes;
  saleOrderStatus?: SalesOrderStatus;
  activeType?: ActiveFilterTypes;
  qbCustomerType?: QBFilterTypes;
  taxableType?: TaxableFilterTypes;
  paymentTypeFilterList?: any;
  posActionTypeList?: any;
  ShiftIdList?: any;
  fromDate?: string;
  toDate?: string;
  asOfDate?: string;
  exportFormat?: ExportFormat;
  parentCategoryFilterList?: any;
  openBalance?: boolean;
  selectedCustomerName?: string;
  orderBys?: string[];
  cogsByCost?: boolean;
  sortColumn?: string;
  balance?: boolean;
  sortDirection?: 'asc' | 'desc' | SortDirection;
  columnFilters: IColumnFilter[];


  public static withToDate(source: ReportFiltersModel): ReportFiltersModel {
    const result = Object.assign({}, source);
    result.toDate = result.toDate;
    if (result.customerFilterList && typeof result.customerFilterList  === 'string') {
      result.customerFilterList = (result.customerFilterList as string).split(',');
    }
    if (result.productFilterList && typeof result.productFilterList  === 'string') {
      result.productFilterList = (result.productFilterList as string).split(',');
    }
    if (result.vendorFilterList && typeof result.vendorFilterList  === 'string') {
      result.vendorFilterList = (result.vendorFilterList as string).split(',');
    }
    if (result.balance) {
      result.columnFilters.push({
        columnName: 'balance',
        operator: '>',
        value: '0'
    });
    }
    return result;
  }


  public static withDateRange(source: ReportFiltersModel): ReportFiltersModel {
    const result = Object.assign({}, source);
    result.fromDate = result.fromDate;
    result.toDate = result.toDate;
    if (result.customerFilterList && typeof result.customerFilterList  === 'string') {
      result.customerFilterList = (result.customerFilterList as string).split(',');
    }
    if (result.productFilterList && typeof result.productFilterList  === 'string') {
      result.productFilterList = (result.productFilterList as string).split(',');
    }
    if (result.vendorFilterList && typeof result.vendorFilterList  === 'string') {
      result.vendorFilterList = (result.vendorFilterList as string).split(',');
    }
    if (result.balance) {
      result.columnFilters.push({
        columnName: 'balance',
        operator: '>',
        value: '0'
    });
    }
    return result;
  }

  clone(cloneReportModel: IReportFilter) {
    this.searchText = cloneReportModel.searchText ;
    this.isDescending = cloneReportModel.isDescending ;
    this.pageNumber = cloneReportModel.pageNumber ;
    this.pageSize = cloneReportModel.pageSize ;
    this.sortColumn = cloneReportModel.sortColumn;
    this.sortDirection = cloneReportModel.sortDirection;
    this.customerFilterList = cloneReportModel.customerFilterList ;
    this.productFilterList = cloneReportModel.productFilterList ;
    this.userIds = cloneReportModel.userIds ;
    this.vendorFilterList = cloneReportModel.vendorFilterList ;
    this.orderType = cloneReportModel.orderType;
    this.saleOrderStatus = cloneReportModel.saleOrderStatus;
    this.activeType = cloneReportModel.activeType;
    this.qbCustomerType = cloneReportModel.qbCustomerType;
    this.taxableType = cloneReportModel.taxableType;
    this.paymentTypeFilterList = cloneReportModel.paymentTypeFilterList;
    this.posActionTypeList = cloneReportModel.posActionTypeList ;
    this.ShiftIdList = cloneReportModel.ShiftIdList
    this.fromDate = cloneReportModel.fromDate ;
    this.toDate = cloneReportModel.toDate ;
    this.asOfDate = cloneReportModel.asOfDate ;
    this.exportFormat = cloneReportModel.exportFormat;
    this.parentCategoryFilterList = cloneReportModel.parentCategoryFilterList ;
    this.openBalance = cloneReportModel. openBalance;
    this.selectedCustomerName = cloneReportModel.selectedCustomerName ;
    this.orderBys = cloneReportModel.orderBys;
    this.cogsByCost = cloneReportModel.cogsByCost;
    this.balance = cloneReportModel.balance;
    return this;
    // TODO Add all- so far add which is require in client side filtering
  }

  public getSelectedFilters(): IFilters {
    const filters: IFilters = {};

    if ( !this.isEmptyOrNull(this.activeType) && this.activeType !== ActiveFilterTypes.All) {
      filters.isActive = this.activeType === ActiveFilterTypes.Active ? true : false;
    }

    if ( !this.isEmptyOrNull(this.qbCustomerType) && this.qbCustomerType !== QBFilterTypes.All) {
      filters.isQBCustomer = this.qbCustomerType === QBFilterTypes.QB ? true : false;
    }

    if ( !this.isEmptyOrNull(this.taxableType) && this.taxableType !== TaxableFilterTypes.All) {
      filters.isTaxable = this.taxableType === TaxableFilterTypes.Taxable ? true : false;
    }

    const parentCategoryIds: string[] = this.parentCategoryFilterList;
    if (parentCategoryIds && parentCategoryIds.length > 0) {
      filters.rootCategoryId = parentCategoryIds;
    }

    return filters;
  }

  isEmptyOrNull(inObject: any) {
    return inObject === undefined || inObject === null;
  }

}
