import { DataService } from '@otrack-lib/core/facade/data.service';
import { Injectable, Inject, enableProdMode } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { ErrorService } from './error.service';
import { ConfigurationService } from '../helper-services/configuration.service';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { LocalStorageService } from '../helper-services/local-storage.service';
import { WINDOW } from '../guards/window';
import { first, switchMap } from 'rxjs/operators';
// import firebase from 'firebase/compat/app';

export interface GoogleUserInfo {
  idToken: string;
  displayName: string;
  photoUrl: string;
  email: string;
}

export interface OnboardResponse {
  uid: string;
  customToken: string;
  companyId: string;
}

export interface LoginResponse {
  companyExist: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  readonly csstTokenCookieName: string = 'csst';
  readonly csstTokenLocalKey: string = 'csst';
  userSignoutEvent$ = new BehaviorSubject(null);
  private _shouldSendVerificationEmail: boolean;
  private http: HttpClient;

  constructor(
    @Inject(WINDOW) private window: Window,
    private localStorage: LocalStorageService,
    private configService: ConfigurationService,
    public afAuth: AngularFireAuth,
    private dataService: DataService,
    private cookieService: CookieService,
    private errorService: ErrorService,
    handler: HttpBackend,
    private configurationService: ConfigurationService
  ) {
    this.http = new HttpClient(handler); // ignore any interceptor for http calls
    // tslint:disable-next-line:max-line-length
    // this.afAuth.authState.subscribe(user => {//storing uid in localstorage as this event doesn't fire on reload but data exists in indexdb
    //   if (user) {
    //     this.localStorage.store('user', user.uid);
    //   } else {
    //     this.localStorage.store('user', null);
    //   }
    // });
  }

  get shouldSendVerificationEmail() {
    return this._shouldSendVerificationEmail;
  }
  setSendVerificationEmailFlag() {
    this._shouldSendVerificationEmail = true;
  }

  resetSendVerificationEmailFlag() {
    this._shouldSendVerificationEmail = false;
  }

  isAuthenticated() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }

  public get csstCookie(): string {
    return this.cookieService.get(this.csstTokenCookieName);
  }

  get isEqualLocalAndCookieCsst(): boolean {
    const localCsstToken = this.localStorage.retrieve(this.csstTokenLocalKey);
    return (localCsstToken && localCsstToken === this.csstCookie);
  }

  get currentUser(): firebase.User {
    return this.afAuth.auth.currentUser;
  }

  async signInWithPopup(provider: any) {
    return await this.afAuth.auth.signInWithPopup(provider);
  }

  async doFacebookLogin() {
    return await this.signInWithPopup(new auth.FacebookAuthProvider());
  }

  async doTwitterLogin() {
    return await this.signInWithPopup(new auth.TwitterAuthProvider());
  }

  // async doGoogleLogin() {
  //   const provider = new auth.GoogleAuthProvider();
  //   provider.addScope('profile');
  //   provider.addScope('email');
  //   return await this.signInWithPopup(provider);
  // }

  signInWithGoogle(): Observable<GoogleUserInfo> {
    const provider = new auth.GoogleAuthProvider();
    return from(this.afAuth.auth.signInWithPopup(provider)).pipe(
      switchMap((result) => {
        return from(result.user.getIdToken()).pipe(
          switchMap((idToken) => {
            if (!idToken) {
              throw new Error('No ID token received');
            }
            return of({
              idToken,
              email: result.user.email,
              displayName: result.user.displayName || '',
              photoUrl: result.user.photoURL || ''
            });
          })
        );
      })
    );
  }

  signInWithCustomToken(token: string): Observable<any> {
    return from(this.afAuth.auth.signInWithCustomToken(token));
  }

  async doRegister(value: any) {
    return await this.afAuth.auth.createUserWithEmailAndPassword(value.email, value.password);
  }

  async getIdToken(forceRefresh?: boolean): Promise<string> {
    return this.currentUser ? this.currentUser.getIdToken(forceRefresh) : null;
  }

  async doLogin(value: any) {
    const userCredential = await this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password);
    const user = userCredential.user;
    if (!user) {
      throw new Error('No user found after sign-in');
    }
    const idTokenResult = await user.getIdTokenResult();
    return (idTokenResult.claims.company_id === -1) ? { companyExist: false } : { companyExist: true };
  }

  async doGoogleLogin(): Promise<LoginResponse> {
    try {
      const provider = new auth.GoogleAuthProvider();
      const userCredential = await this.afAuth.auth.signInWithPopup(provider);
      const user = userCredential.user;
      if (!user) {
        throw new Error('No user returned from Google Sign-In');
      }
      const idTokenResult = await user.getIdTokenResult();
      const companyId = idTokenResult.claims.company_id as number | undefined;
      return { companyExist: companyId !== -1 && companyId !== undefined };
    } catch (error) {
      console.error('Google Login error:', error);
      throw error;
    }
  }

  async doAppLogin(value: any) {
    await this.afAuth.auth.signInWithEmailAndPassword(value.email, value.password);
    const idTokenResult = await this.currentUser.getIdTokenResult();
    return idTokenResult.claims.company_id;
  }

  // async doGoogleCloudSignInCall(token: string) {
  //   const headers = new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   });
  //   const body = { idToken: token };

  //   const options = { headers, withCredentials: true };
  //   await this.http.post(this.configurationService.config.signupFunction, body, options).toPromise();
  //   this.localStorage.store(this.csstTokenLocalKey, this.csstCookie);
  // }

  doGoogleCloudSignInCall(idToken: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const body = { idToken };

    const options = { headers, withCredentials: true };
    return this.http.post(this.configurationService.config.signupFunction, body, options);
  }

  async doGoogleCloudCheckStatusCall() {
    try {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers, withCredentials: true };
      // now call the checkSign Google Function to validate session cookie
      let body: {cssToken: any, sessionCookie?: any} = { cssToken: this.csstCookie };
      if (this.configurationService.config.env.toUpperCase() === 'LOCAL') {
       body = { ...body,
        sessionCookie: this.configurationService.config.localToken };
      }
      const response = <any>await this.http.post(this.configurationService.config.checkstatusFunction, body, options).toPromise();
      try {
        // get the custom token and login using customtoken
        const signInResponse = await this.afAuth.auth.signInWithCustomToken(response.customToken);
        // console..log(signInResponse.user.uid);
      } catch (error) {
        this.errorService.setError(error);
        this.logout();
        return false;
      }
    } catch (error) {
      this.errorService.setError(error);
      await this.logoutClient(true);
      return false;
    }
    return true;
  }

  async reloadSession(csstToken: string): Promise<boolean> {
    const sessionCheck = await this.doGoogleCloudCheckStatusCall();
    if (sessionCheck) {
      this.localStorage.store(this.csstTokenLocalKey, csstToken);
    }
    return sessionCheck;
  }

  async logout(): Promise<void> {
    try {
      this.dataService.resetData(); // clear datastore
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      const options = { headers, withCredentials: true };
      await this.http.post(this.configurationService.config.signoutFunction, '', options).toPromise();
      await this.logoutClient();
    } catch (error) {
      console.error(error);
      await this.logoutClient();
    }
  }

  async publicUserLogout() {
    await this.afAuth.auth.signOut();
  }

  async logoutClient(redirectToLoginPage: Boolean = true, redirectToOrigin: Boolean = false): Promise<void> {
    await this.afAuth.auth.signOut();
    this.clearClientSession();
    if (redirectToLoginPage) {
      const returnUrlParam = this.window.location.href.indexOf(this.configurationService.config.loginPageUrl) === 0
        ? null : `returnUrl=${this.window.location.href}`;
      window.location.href = `${this.configurationService.config.loginPageUrl}?${returnUrlParam}`;
    } else if (redirectToOrigin) {
      window.location.href = window.location.origin;
    }
  }

  private clearClientSession(): void {
    this.localStorage.remove(this.csstTokenLocalKey);
    this.userSignoutEvent$.next(null); // trigger the event so userservice can set this.user to null

    // Clear everything saved in SessionStorage on Logout
    if (sessionStorage) {
      sessionStorage.clear();
    }
  }

  async updatePassword(currentPassword: string, newPassword: string): Promise<void> {
    const credential = auth.EmailAuthProvider.credential(
      this.currentUser.email,
      currentPassword
    );
    await this.currentUser.reauthenticateWithCredential(credential);
    await this.currentUser.updatePassword(newPassword);
  }

  async sendPasswordResetEmail(emailAddress: string, url: string = this.configService.config.loginPageUrl): Promise<void> {
    const actionCodeSettings = {
      url: url
    };
    await this.afAuth.auth.sendPasswordResetEmail(emailAddress, actionCodeSettings);
  }

  async sendEmailVerificationEmail(): Promise<void> {
    const actionCodeSettings = {
      url: this.configService.config.loginPageUrl
    };
    await this.currentUser.sendEmailVerification(actionCodeSettings);
  }
}
